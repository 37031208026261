import React, { useContext } from 'react';

import { ProductDataContext } from '@components/common/ProductFullResponsive/productContext';
import ProductImageAvailability from '@components/common/ProductFullResponsive/ProductImage/Availability';
import ProductImageLike from '@components/common/ProductFullResponsive/ProductImage/Like';
import ProductImageTopActions from '@components/common/ProductFullResponsive/ProductImage/TopActions';
import { IProductInList } from '@interfaces/product';


interface IProductImageProps {
  pictureLoaded: boolean;
  product: IProductInList;
  productId?: number;
}

const ProductImageAdditional: React.FunctionComponent<IProductImageProps> = ({
  pictureLoaded,
  product,
  productId
}) => {

  const { currentColor, withLike } = useContext(ProductDataContext);
  const currentProductId = productId || product.colors[0]?.productId;

  return (
    <>
      {withLike && currentColor && <ProductImageLike currentProductId={currentProductId} product={product} color={currentColor} />}
      <ProductImageAvailability
        pictureLoaded={pictureLoaded}
      />
      <ProductImageTopActions />
    </>
  );
};

export default ProductImageAdditional;
