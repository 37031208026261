import classNames from 'classnames';
import React, {useContext } from 'react';
import { useSelector } from 'react-redux';

import { ProductDataContext } from '@components/common/ProductFullResponsive/productContext';
import ErrorBoundary from '@components/common/ErrorBoundary';
import { usePriceData } from '@hooks/usePriceData';
import { calculateDiscountPercent } from '@components/pages/productsList/utils';
import { useTranslation } from '@hooks/useTranslation';

import { IStore } from '@interfaces/store';
import { ISiteSettings } from '@interfaces/pageData/reducer';

import itemStyles from '../styles';



const ProductImageTopActions: React.FunctionComponent<{}> = () => {
  const outletTag = useSelector<IStore, ISiteSettings['enable30dayPrice'] | undefined>((state: IStore) => state.pageData.data.siteSettings?.outletTag);
  const { t } = useTranslation();

  const { currentColor, rightAction, item } = useContext(ProductDataContext);

  const isFresh = item?.fresh;
 const isOutletProduct = currentColor?.outlet || false;

  const { discountPrice, price, withDiscount } = usePriceData(currentColor?.price);

  const discountPercent = withDiscount ? calculateDiscountPercent({ priceDiscount: discountPrice, price }) : null;
  const hasDiscountPrice = withDiscount && discountPercent;
  const discountText = isOutletProduct && outletTag ? 'Outlet' : `-${discountPercent}%`;

  const classesItem = itemStyles();

  return (
    <ErrorBoundary>
      {(rightAction || isFresh || hasDiscountPrice || isOutletProduct) && (
        <div className={classesItem.productFullTopAction}>
          {hasDiscountPrice && (
            <div
              className={classNames(classesItem.tag, classesItem.discountTag, classesItem.tagBlur)}>{discountText}</div>
          )}
          {isFresh && <div
            className={classNames(classesItem.tag, classesItem.freshTag, classesItem.tagBlur)}>{t('product.freshTag')}</div>}
          {rightAction && rightAction}
        </div>
      )}
    </ErrorBoundary>
  );
};

export default ProductImageTopActions;
