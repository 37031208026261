import classNames from 'classnames';
import React, { useContext, useState } from 'react';

import { ProductDataContext } from '@components/common/ProductFullResponsive/productContext';
import ProductImageAdditional from '@components/common/ProductFullResponsive/ProductImage/additional';
import PictureShow from '@components/common/PictureShow';
import { IProductInList } from '@interfaces/product';

import itemStyles from './../styles';

interface IProductImageProps {
  product: IProductInList;
}

const ProductImage: React.FunctionComponent<IProductImageProps> = ({
  product,
}) => {
  const classesItem = itemStyles({});

  const [hoverImage, handleHoverImage] = useState(false);
  const [hoveredImage, handleHoveredImage] = useState(false);
  const [pictureLoaded, changePictureLoaded] = useState(false);
  const [pictureSecondLoaded, changePictureSecondLoaded] = useState(false);


  const { currentColor } = useContext(ProductDataContext);

  const imageFirst = currentColor?.pictures[0]?.urlMedium;
  const imageSecond = currentColor?.pictures[1] ? currentColor?.pictures[1].urlMedium : imageFirst;

  return (
    <div
      className={classesItem.productFullImage}
      onMouseEnter={() => {
        handleHoverImage(true);
        if (!hoveredImage) {
          handleHoveredImage(true);
        }
      }}
      onMouseLeave={() => handleHoverImage(false)}
    >
      <PictureShow
        onLoaded={() => changePictureLoaded(true)}
        className={classNames(classesItem.productFullImageImg, {
          [classesItem.productFullImageImgHide]: hoverImage && pictureSecondLoaded,
        })}
        src={imageFirst || ''}
      />
      {hoveredImage && (
        <PictureShow
          onLoaded={() => changePictureSecondLoaded(true)}
          className={classNames(classesItem.productFullImageImg, {
            [classesItem.productFullImageImgHide]: !hoverImage || !pictureSecondLoaded,
          })}
          src={imageSecond || ''}
        />
      )}
      <ProductImageAdditional product={product} pictureLoaded={pictureLoaded} />
    </div>
  );
};

export default ProductImage;
