import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { IProductPrice } from '@interfaces/product';
import { EISaleTagType, ICustomBanner, ISaleTagAttributes } from '@interfaces/customBanners/actions';
import { ECustomBannerType } from '@constants/customBanners';
import { IStore } from '@interfaces/store';

interface IUseSaleTagProps {
  campaignId: number | undefined | null;
  productPrice?: IProductPrice;
}

const useSaleTag: (props: IUseSaleTagProps) => { tagToShow: ICustomBanner | undefined } = ({
  campaignId,
  productPrice,
}) => {

  const filterCampaign = (banner: ICustomBanner): boolean => {
    const bannerCampaignIds: string[] = (banner.attributes as ISaleTagAttributes).campaign_id || [];
    return bannerCampaignIds.includes(String(campaignId));
  };

  const filterCampaignEmpty = (banner: ICustomBanner): boolean => {
    const bannerCampaignIds: string[] = (banner.attributes as ISaleTagAttributes).campaign_id || [];
    return bannerCampaignIds.length === 0;
  };

  const customBannersOther = useSelector<IStore, ICustomBanner[] | undefined>((state: IStore) => state.pageData.data.customBanners?.other);

  const saleTags = useMemo(
    () => customBannersOther?.filter(item => item.type === ECustomBannerType.saletag) || [],
    [customBannersOther],
  );
  const saleTagsDefault = useMemo(
    () => saleTags.filter(item => (item.attributes as ISaleTagAttributes).type === EISaleTagType.default) || [],
    [saleTags],
  );
  const saleTagsMinPurchasePrice = useMemo(
    () => saleTags.filter(item => (item.attributes as ISaleTagAttributes).type === EISaleTagType.campaignMinPurchasePrice) || [],
    [saleTags],
  );

  const tagToShowDefault = useMemo(() => {
    if (campaignId) {
      return saleTagsDefault.find(filterCampaign) || saleTagsDefault.find(filterCampaignEmpty);
    }
    return saleTagsDefault.find(filterCampaignEmpty);
  }, [saleTagsDefault, campaignId]);

  const tagToShowMinPrice = useMemo(() => {
    if (campaignId) {
      return saleTagsMinPurchasePrice.find(filterCampaign) || saleTagsMinPurchasePrice.find(filterCampaignEmpty);
    }
    return saleTagsMinPurchasePrice.find(filterCampaignEmpty);
  }, [saleTagsMinPurchasePrice, campaignId]);

  const tagToShow = (productPrice?.campaignMinPurchasePrice && tagToShowMinPrice) ? tagToShowMinPrice : tagToShowDefault;

  return { tagToShow };
};

export default useSaleTag;
