import { Action } from 'redux';

import { ECustomBannerType } from '@constants/customBanners';
import { IContentSettings, IContentShow, ISwitchButtonStyles } from '@interfaces/pageData/reducer';
import * as actions from '@constants/actions/customBanners';

type IImage = string;

export interface ICustomBanner {
  id: number;
  attributes: TCustomBannerAttributes;
  type: ECustomBannerType;
  content_show: IContentShow;
  content_settings: IContentSettings;
}


export interface ICustomBannerAttributes {
  image?: IImage;
  image_mobile?: IImage;
  image_app?: IImage;
  text?: string;
  text_additional?: string;
  link?: string;
  open_new_window?: boolean;
  url?: string;
  color_code?: string;
  id?: number;
  closeButton?: boolean;
  uid?: string;
}

export interface INotificationBannerAttributes {
  closeButton: boolean;
  link: string;
  link_modal?: IModalLink;
  open_new_window: boolean;
  text: string;
  uid: string;
}

export interface ISaleSwitchBannerAttributes {
  text: string;
  text_additional: string;
  switch_button: ISwitchButtonStyles;
  campaign_id?: string[];
  link_modal?: IModalLink;
}

export interface IBannerAttributes {
  image: IImage;
  image_mobile: IImage;
  image_app: IImage;
  link: string;
  open_new_window: boolean;
}

export enum EISaleTagType {
  default = 'default',
  campaignMinPurchasePrice = 'campaignMinPurchasePrice'
}

export interface ISaleTagAttributes {
  text: string;
  campaign_id?: string[];
  type: EISaleTagType;
}

export type TCustomBannerAttributes =
  INotificationBannerAttributes
  | ISaleSwitchBannerAttributes
  | IBannerAttributes
  | ISaleTagAttributes;


export interface ICustomBannerClosed {
  id: string;
}

export interface ICustomBannersClose extends Action {
  payload: ICustomBannerClosed[];
  type: actions.CUSTOM_BANNERS_CLOSE;
}


export type ICustomBannersActions = ICustomBannersClose;

export interface IBaseModal {
  text: string;
  closeButton: string;
}

export interface IModalLink {
  title: string;
  modal?: number | null;
}
