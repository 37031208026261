import classNames from 'classnames';
import React from 'react';
import Skeleton from '@mui/material/Skeleton';

import { ProductDataContext } from '@components/common/ProductFullResponsive/productContext';
import ErrorBoundary from '@components/common/ErrorBoundary';
import ProductFullResponsiveDataColors from '@components/common/ProductFullResponsive/ProductDataColors';
import ProductFullResponsiveDataPrice from '@components/common/ProductFullResponsive/ProductDataPrice';
import ProductFullResponsiveDataSizes from '@components/common/ProductFullResponsive/ProductDataSizes';
import ProductImage from '@components/common/ProductFullResponsive/ProductImage';
import Price30Days from '@components/pages/product/product_detail/detail-area/DetailAreaTitlePriceContainer/price30Days';

import styles from './styles';


const ProductFullResponsiveData: React.FunctionComponent<{}> = () => {
  const classes = styles();

  return (
    <ErrorBoundary>
      <ProductDataContext.Consumer>
        {({currentColor, item, isGiftCard, productId})=> (<>
          {item && (<>

          <ProductImage product={item} />
          <div className={classes.productFullNameItem}>
            {item?.brand?.brand || <Skeleton animation={false} width={'30%'} />}
          </div>
          <div className={classes.productFullNameItem}>
            {item?.modelType || <Skeleton animation={false} width={'50%'} />}
          </div>
          <ProductFullResponsiveDataPrice item={item} productId={productId} />
          {!isGiftCard && (
            <div className={classNames(classes.productFullCases, classes.productFullSizes)}>
              <ProductFullResponsiveDataSizes item={item} />
              <ProductFullResponsiveDataColors item={item} productId={productId} />
            </div>
          )}
          <Price30Days color={currentColor} isList={true} />
          </>)}
        </>)}
      </ProductDataContext.Consumer>

    </ErrorBoundary>
  );
};

export default ProductFullResponsiveData;
