import { ELanguages } from '@constants/languages';
import { PageTypes } from '@constants/pageTypes';
import { ESort, ESortOrder } from '@constants/sort';
import { ERetailOrderStatus } from '@interfaces/account';
import { IWrappedCategorySectionMapped } from '@interfaces/category';
import { ICustomBanner } from '@interfaces/customBanners/actions';
import { IHistoryState } from '@interfaces/history';
import { IBlogPostDataObject, IHomePageComponentDataObject } from '@interfaces/home';
import { ILotteryImages } from '@interfaces/lottery';
import { IMegaMenuLinkDataObject } from '@interfaces/megaMenu';
import { IPriceListCategory } from '@interfaces/priceList/reducer';
import { IPrivacyPolicyModal } from '@utils/siteSettings';

export interface IAuthor {
  name: string;
  image: string;
  text: string;
}

export interface IContentBase {
  content?: string | IFaqItem[] | GridGalleryContent | IPriceListCategory[];
  type?: string;
  content_settings: IContentSettings;
}

export interface IContent extends IContentBase {
  video?: any;
  images?: string[];
  link?: string;
  showBeforeH1?: boolean;
  showInDesktop?: boolean;
  showInMobile?: boolean;
  src?: string;
  top: string;
  bottom: string;
}

export interface IContentSettings {
  color?: string | null;
  colorBackground?: string | null;
  className: string | null;
  fontSize: string | null;
  style: string | null;
  content_id?: string | null;
}

export interface IContentShow {
  show: boolean;
  show_in_mobile: boolean;
  show_in_app: boolean;
  user_auth: EContentShowUserAuth;
}

export enum EContentShowUserAuth {
  all = 'all',
  logged_in = 'logged_in',
  logged_out = 'logged_out',
}

export interface ISwitchButtonStyles {
  colorThumb: string;
  colorTrack: string;
  colorTrackActive: string;
}

export interface IFaqItem {
  question: string;
  answer: string;
}

export interface IFilter {
  sexId: number | null;
  categoryId: number | null;
  sort: ESort | null;
  sortOrder: ESortOrder | null;
  pageSize: number | null;
  sale: boolean | null;
  fresh: boolean | null;
}

export interface IProductDetailPage {
  title: string;
  url: string;
}

export interface ICartPageSettings {
  emptyShowPopular: boolean | null;
  emptyShowSuggested: boolean | null;
  emptyShowTrending: boolean | null;
}

export interface IWishListPageSettings {
  emptyShowPopular: boolean | null;
}

export interface IRedisSettings {
  key: string;
  seconds: number;
}

export interface ISiteSettings {
  editMode?: boolean;
  colors?: ISiteSettingsColors;
  disableAppleLoginApp?: boolean;
  disableFaceBookLoginApp?: boolean;
  disableGoogleLoginApp?: boolean;
  disableSmartIdLoginApp?: boolean;
  disableAppleLoginWeb?: boolean;
  disableFaceBookLoginWeb?: boolean;
  disableGoogleLoginWeb?: boolean;
  disableSmartIdLoginWeb?: boolean;
  disableReactCache?: boolean;
  disableStrapiCache?: boolean;
  enableLogRocket?: boolean;
  enable30dayPrice?: boolean;
  overrideLogRocketCookies?: boolean;
  privacyPolicyModal?: IPrivacyPolicyModal;
  redisTTL?: IRedisSettings[];
  loyaltyShowAccessCard?: boolean;
  loyaltyShowQrScanner?: boolean;
  listDefaultSort?: string;
  outletTag?: boolean;
}


export interface ISiteSettingsColors {
  sidemenu: string;
  account: string;
  frontpage: string;
  footerBackground: string;
  menuBarBackground: string;
  menuBarColor: string;
}

export interface IMobileMenuItem {
  show: boolean;
  showInApp: boolean;
}

export interface IMobileMenuItemBrands extends IMobileMenuItem {
  brands: IBrand[];
}

export interface IMobileMenuItemSignUp extends IMobileMenuItem {
  title: string;
  text: string;
  button_text: string;
  image: string;
}

export interface IMobileMenu {
  brands: IMobileMenuItemBrands;
  categories: IMobileMenuItem;
  notifiction: IMobileMenuItem;
  popular_products: IMobileMenuItem;
  sign_up: IMobileMenuItemSignUp;
  important: { showInMobile: boolean };
}

export interface IMegaMenu {
  brands: IMobileMenuItemBrands;
  categories: IMobileMenuItem;
  notifiction: IMobileMenuItem;
  popular_products: IMobileMenuItem;
  sign_up: IMobileMenuItem;
}

export interface INotification {
  id: number;
  title: string;
  content: string;
  link: string | null;
  loggedInOnly: boolean | null;
  image: string | null;
}

export interface IFooterLink {
  title: string;
  url: string;
}

export interface IPageDataFooter {
  about: IFooterLink[];
  family: IFooterLink[];
  help: IFooterLink[];
  shop: IFooterLink[];
}

export interface ICustomBanners {
  product_list: ICustomBanner[];
  product_detail: ICustomBanner[];
  cart: ICustomBanner[];
  purchase_success: ICustomBanner[];
  other: ICustomBanner[];
}

export interface IPageData {
  alias?: string;
  author?: IAuthor;
  banner?: IContentBanner;
  blog_posts?: any;
  blogTags?: string[];
  breadcrumb?: IBreadcrumb[];
  components?: IHomePageComponentDataObject[];
  content?: IContent | IContent[];
  contentData?: IContentData;
  customBanners?: ICustomBanners;
  date?: string;
  detailImage?: string;
  description?: string;
  employees?: IEmployees[];
  images?: string[] | ILotteryImages;
  items?: IFaqItem[] | ILookBook[] | IBlogPostDataObject[];
  filter?: IFilter;
  footer?: IPageDataFooter;
  image?: string;
  lastActiveotification?: INotification;
  message?: string;
  meta?: any;
  orderRetailStatus?: IOrderRetailStatus[];
  orderStatus?: IOrderStatus[];
  pages?: IPageReference[];
  product_detail_page?: IProductDetailPage;
  productId?: string;
  productIds?: string[] | number[];
  search_page?: any;
  seo?: ISeo;
  cartPageSettings?: ICartPageSettings;
  wishListPageSettings?: IWishListPageSettings;
  siteSettings?: ISiteSettings;
  slogan?: string;
  title?: string;
  totalCount?: number;
  translations?: Record<string, string>[];
  ['country-select']?: ICountrySelectItem[];
  ['mobile-menu']?: IMobileMenu;
  ['mega-menu']?: IMegaMenuLinkDataObject[];
  ['import-menu']?: IImportantMenu;
}

export interface IImportantMenu {
  banner: string;
  bottomKids: IImportantMenuItem[];
  bottomMen: IImportantMenuItem[];
  bottomWomen: IImportantMenuItem[];
  brandsKids: IImportantMenuItem[];
  brandsMen: IImportantMenuItem[];
  brandsWomen: IImportantMenuItem[];
  kids: IImportantMenuItem[];
  kidsIcons: {
    boysIcon: string;
    boysPage: {
      url: string;
      title: string;
    };
    girlsIcon: string;
    girlsPage: {
      url: string;
      title: string;
    };
  };
  men: IImportantMenuItem[];
  women: IImportantMenuItem[];
  banners: {
    kids: string;
    men: string;
    women: string;
  };
}

export interface IImportantMenuItem {
  icon: string;
  name: string;
  url: string;
  showOnlyOnMobile: boolean;
}

export interface IContentPageDataContent {
  content: string;
  type: string;
}

export interface IContentPageData {
  id: number;
  title: string;
  content: IContentPageDataContent[];
}

export interface IContentPageDataWrapper {
  [key: string]: IContentPageData;
}

export interface ILastMainPage {
  type: PageTypes;
  url: string | null;
}

export interface IPageDataReducer {
  categoryWrapped: IWrappedCategorySectionMapped[];
  contentPageData: IContentPageDataWrapper;
  countryCode: string | null;
  emptyCountryLang: boolean;
  data: IPageData;
  languageCode: ELanguages | null;
  lastMainPage: null | IHistoryState;
  loading: boolean;
  loadingPolicy: boolean;
  navBar: INavBarSettings | null;
  policy: IFaqItem[];
  shallowPageChange: boolean;
  type: PageTypes | null;
  typeNext: PageTypes | null;
}

export interface IPageReference {
  alias: string;
  id: number;
  type: string | PageTypes;
  url: string;
  filter?: IFilter;
}

export interface IEmployeeTag {
  name: string;
  sortOrder: number;
}

export interface IEmployees {
  name: string | null;
  email: string | null;
  description: string | null;
  image: string | null;
  occupations: string[] | null;
  sortOrder: number;
  tags: IEmployeeTag[] | null;
}

export interface IContentBanner {
  images: string[];
  link?: string;
  showInMobile: boolean;
}

export interface IContentData {
  banners?: IContentBanner[];
  facebook: string | null;
  instagram: string | null;
  seo: string | null;
  text1: string | null;
  text2: string | null;
  title: string | null;
  twitter: string | null;
  youtube: string | null;
  supportNumber: string | null;
  supportEmail: string | null;
  desktopBanner: string | null;
  mobileBanner: string | null;
}

export interface IOrderStatusBase<T> {
  color: string;
  id: T;
  name: string;
  icon: string;
}

export type IOrderStatus = IOrderStatusBase<number>;
export type IOrderRetailStatus = IOrderStatusBase<ERetailOrderStatus>;

export interface IBreadcrumb {
  title: string;
  url: string;
}

export interface IBrand {
  link: string;
  media: string;
  name?: string;
}

export interface ISeo {
  description?: string;
  h1?: string;
  image?: string | null;
  short_story?: string;
  title?: string;
}

export interface ILookBook {
  author: IAuthor;
  alias: string;
  images: string[];
  productIds: string[];
  seo: ISeo;
  slogan: string;
  title: string;
}

export interface ICountrySelectItem {
  code: string;
  flag: string;
  title: string;
  languages: ILanguageSelectItem[];
}

export interface ILanguageSelectItem {
  code: string;
  flag: string;
  title: string;
}

export interface PolicyItem {
  answer: string;
  question: string;
}

export interface INavBarSettings {
  showTranslations: boolean;
  translationHome: string;
  translationLoyalty: string;
  translationMenu: string;
  translationWishlist: string;
  translationAccount: string;
  translationCart: string;
  locale: string;
}

export interface GridGalleryContent {
  colorTagBackground?: string;
  colorTagText?: string;
  gridGap: string;
  gridTemplateColumns: string;
  gridTemplateRows: string;
  gallery: GridGalleryImage[];
  withCaption?: boolean;
  useSimpleGrid?: boolean;
  rgg_rowHeight?: number;
  rgg_maxRows?: number;
  rgg_margin?: number;
}


export interface GridGalleryImage {
  id: number;
  alt: string;
  caption: string;
  src: string;
  original: string;
  height: number;
  width: number;
}
