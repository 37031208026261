import { listSizeBig } from '@constants/index';
import { nativeSettingsBrowserStorageKey } from '@reducers/nativeSettings/const';
import {
  getSessionIdCookieKey,
  KEY_COOKIE_FIRSTRUN,
  KEY_COUNTRY_LANGUAGE,
  KEY_FACEBOOK_RESPONSE,
  KEY_TOKEN,
} from '@utils/cookies';

// Base
export const getLocalStorageValue = (key: string): string | null => {
  if (typeof window === 'undefined' || !('localStorage' in window)) {
    return null;
  }
  return window.localStorage.getItem(key);
};

export const setLocalStorageValue = (key: string, value: string): void => {
  if (typeof window !== 'undefined') {
    window.localStorage.setItem(key, value);
  }
};

export const resetLocalStorageValue = (key: string): void => {
  if (typeof window !== 'undefined') {
    window.localStorage.removeItem(key);
  }
};

// Common
export const getSessionIdFromLocalStorage = (countryCode: string): string | null => {
  const sessionKey = getSessionIdCookieKey(countryCode);
  if (sessionKey) {
    return getLocalStorageValue(sessionKey);
  }
  return null;
};

export const setSessionIdInLocalStorage = (countryCode: string, value: string): void => {
  const sessionKey = getSessionIdCookieKey(countryCode);
  if (sessionKey) {
    setLocalStorageValue(sessionKey, value);
  }
};

export const resetSessionIdInLocalStorage = (countryCode: string): void => {
  const sessionKey = getSessionIdCookieKey(countryCode);
  if (sessionKey) {
    resetLocalStorageValue(sessionKey);
  }
};

export const getLocaleFromLocalStorage = (): string | null => {
    return getLocalStorageValue(KEY_COUNTRY_LANGUAGE);
};

export const setLocaleInLocalStorage = (value: string): void => {
    setLocalStorageValue(KEY_COUNTRY_LANGUAGE, value);
};

export const getListSizeFromLocalStorage = (): string | null => {
    return getLocalStorageValue(listSizeBig);
};

export const setListSizeInLocalStorage = (value: string): void => {
    setLocalStorageValue(listSizeBig, value);
};


export const getTokenFromLocalStorage = (): string | null => {
    return getLocalStorageValue(KEY_TOKEN);
};

export const setTokenLocalStorage = (value: string): void => {
    setLocalStorageValue(KEY_TOKEN, value);
};

export const resetTokenLocalStorage = (): void => {
    resetLocalStorageValue(KEY_TOKEN);
};

export const getFirstRunFromLocalStorage = (): string | null => {
  return getLocalStorageValue(KEY_COOKIE_FIRSTRUN);
};

export const setFirstRunLocalStorage = (value: string): void => {
  setLocalStorageValue(KEY_COOKIE_FIRSTRUN, value);
};

export const getNativeSettingsFromLocalStorage = (): string | null => {
  return getLocalStorageValue(nativeSettingsBrowserStorageKey);
};

export const setNativeSettingsToLocalStorage = (value: string): void => {
  if(value){
    setLocalStorageValue(nativeSettingsBrowserStorageKey, JSON.stringify(value));
  }
};

export const setFBResponseLocalStorage = (value: string): void => {
  setLocalStorageValue(KEY_FACEBOOK_RESPONSE, value);
};

export const resetFBResponseLocalStorage = (): void => {
  resetLocalStorageValue(KEY_FACEBOOK_RESPONSE);
};
