import React, { PropsWithChildren, ReactNode } from 'react';

import { ProductDataContext } from '@components/common/ProductFullResponsive/productContext';
import ErrorBoundary from '@components/common/ErrorBoundary';
import Link from '@components/common/Link';
import ProductFullResponsiveData from '@components/common/ProductFullResponsive/ProductData';
import { PageTypes } from '@constants/pageTypes';
import { IProductColor, IProductColorMini, IProductInList } from '@interfaces/product';
import { isProductGiftCard, isProductNotAvailable } from '@utils/product';

import styles from './styles';

interface IProductFullProps {
  item: IProductInList;
  productUrl?: string;
  productId?: number;
  rightAction?: ReactNode;
  sizeId?: number;
  withLike?: boolean;
}

const ProductFullResponsive: React.FunctionComponent<IProductFullProps & PropsWithChildren> = ({
  children,
  item,
  productId,
  rightAction,
  withLike = true,
  sizeId,
  productUrl,
}) => {
  const classes = styles();

  const findCurrentColor = (color: IProductColor | IProductColorMini) => String(color.productId) === String(productId);
  const currentColor: IProductColor | undefined = productId ? item.colors.find(findCurrentColor) : item.colors[0];

  const isGiftCard: boolean = isProductGiftCard(item);
  const isNotAvailable = isProductNotAvailable(item);
  return (
    <ErrorBoundary>
      <ProductDataContext.Provider value={{currentColor, isGiftCard, isNotAvailable, productId, sizeId, withLike, item, rightAction}}>
      <div className={classes.productFull}>
        {productUrl ? (
          <Link href={productUrl} pageType={PageTypes.productDetail} className={classes.productLink}>
              <ProductFullResponsiveData />
          </Link>
        ) : <ProductFullResponsiveData/>}
        {!isNotAvailable && children}
      </div>
      </ProductDataContext.Provider>
    </ErrorBoundary>
  );
};

export default ProductFullResponsive;
