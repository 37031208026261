import {
    getAllSexes, getAndMapSexes, mapDataToFilter
} from '@components/pages/productsList/Filter/Group/utils';
import { miniSite } from '@constants/config';
import { EGender } from '@constants/gender';
import { itemsPerPage } from '@constants/index';
import { PageTypes } from '@constants/pageTypes';
import { IWrappedCategorySectionMapped } from '@interfaces/category';
import { IFilter, IPageData } from '@interfaces/pageData/reducer';
import { EProductsListFilter, IProductsList, IProductsListDataFilter, ISexItem } from '@interfaces/productsList';
import { IProductListFilter } from '@interfaces/productsList/reducer';
import { IStore } from '@interfaces/store';
import { IFilterData, IFilterDataMapped } from '@reducers/productsList/useState';

export const checkIsFiltered = (productsListFilterReducer, filterData) => {
  const isFiltered =
    productsListFilterReducer[EProductsListFilter.brandId].length > 0 ||
    productsListFilterReducer[EProductsListFilter.categoryId].length > 0 ||
    productsListFilterReducer[EProductsListFilter.categoryIdP].length > 0 ||
    productsListFilterReducer[EProductsListFilter.colorGroupId].length > 0 ||
    productsListFilterReducer[EProductsListFilter.sizes].length > 0 ||
    (productsListFilterReducer[EProductsListFilter.priceFrom] !== null &&
      Number(productsListFilterReducer[EProductsListFilter.priceFrom]) !==
      Number(filterData[EProductsListFilter.priceFrom])) ||
    (productsListFilterReducer[EProductsListFilter.priceTo] !== null &&
      Number(productsListFilterReducer[EProductsListFilter.priceTo]) !==
      Number(filterData[EProductsListFilter.priceTo]));
  return isFiltered;
};


export const getMappedData = (filterData: IFilterData, activeCategories: string[], pageSexId?: number | string | null) => {
  const filterDataMapped: IFilterDataMapped = {
    [EProductsListFilter.ageId]: mapDataToFilter(
      EProductsListFilter.ageId,
      filterData[EProductsListFilter.ageId],
      undefined,
      pageSexId
    ),
    [EProductsListFilter.brandId]: mapDataToFilter(
      EProductsListFilter.brandId,
      filterData[EProductsListFilter.brandId],
      undefined,
      pageSexId
    ),
    [EProductsListFilter.categoryId]: mapDataToFilter(
      EProductsListFilter.categoryId,
      filterData[EProductsListFilter.categoryId],
      activeCategories,
    ),
    [EProductsListFilter.categoryIdP]: mapDataToFilter(
      EProductsListFilter.categoryIdP,
      filterData[EProductsListFilter.categoryIdP],
    ),
    [EProductsListFilter.colorGroupId]: mapDataToFilter(
      EProductsListFilter.colorGroupId,
      filterData[EProductsListFilter.colorGroupId],
    ),
    [EProductsListFilter.sexId]: mapDataToFilter(EProductsListFilter.sexId, filterData[EProductsListFilter.sexId]),
    [EProductsListFilter.sexIdMob]: mapDataToFilter(EProductsListFilter.sexIdMob, filterData[EProductsListFilter.sexIdMob]),
    [EProductsListFilter.sizes]: mapDataToFilter(EProductsListFilter.sizes, filterData[EProductsListFilter.sizes]),
    [EProductsListFilter.fits]: mapDataToFilter(EProductsListFilter.fits, filterData[EProductsListFilter.fits]),
    [EProductsListFilter.waists]: mapDataToFilter(EProductsListFilter.waists, filterData[EProductsListFilter.waists]),
  };

  return filterDataMapped;
};


type TMapFilterData = (pageDataType: string | null, pageDataData: IPageData, categoryWrappedState: IWrappedCategorySectionMapped[], dataFilter: IProductsListDataFilter, productListFilter: IProductListFilter) => IFilterData;
export const mapFilterData: TMapFilterData = (pageDataType, pageDataData, categoryWrappedState, dataFilter, productListFilter) => {
  const pageSexId = getPageSexIdNew(dataFilter.sexes || [], pageDataData.filter);

  let categoryWrapped: IWrappedCategorySectionMapped | null = {
    categories: [],
    popularCategories: [],
    sexId: 0
  };
  // temporarily disable these categories
  const disabledCategoryIds = [175, 169, 172];

  const addWrappedCategories = (id: number) => {
    const wrapped = categoryWrappedState.find((category) => String(category.sexId) === String(id));
    if (categoryWrapped && wrapped?.categories?.length) {
      categoryWrapped.categories.push(...wrapped.categories.filter((category) => !disabledCategoryIds.includes(Number(category.categoryId)) && !categoryWrapped?.categories.some(({ categoryId }) => categoryId === category.categoryId)));
    }
    if (categoryWrapped && wrapped?.popularCategories?.length) {
      categoryWrapped.popularCategories.push(...wrapped.popularCategories.filter((category) => !disabledCategoryIds.includes(Number(category.categoryId)) && !categoryWrapped?.categories.some(({ categoryId }) => categoryId === category.categoryId)));
    }
  };

  if (pageSexId && [EGender.boy, EGender.girl].includes(pageSexId)) {
    addWrappedCategories(pageSexId);
  } else if (pageDataType === PageTypes.kids) {
    for (let id=4; id<6; id+=1) {
      addWrappedCategories(id);
    }
  } else {
    categoryWrapped = (pageSexId && categoryWrappedState.find((category) => String(category.sexId) === String(pageSexId))) || null;
  }

  const filterData: IFilterData = {
    [EProductsListFilter.ageId]: dataFilter?.filterAges ?? [],
    [EProductsListFilter.brandId]: dataFilter?.filterBrands ?? [],
    [EProductsListFilter.categoryId]: categoryWrapped?.categories || [],
    [EProductsListFilter.categoryIdP]: (!miniSite && categoryWrapped?.popularCategories) || [],
    [EProductsListFilter.colorGroupId]: dataFilter?.filterColors ?? [],
    [EProductsListFilter.fits]: dataFilter?.filterFits ?? undefined,
    [EProductsListFilter.priceFrom]: dataFilter?.filterMinPrice ?? null,
    [EProductsListFilter.priceTo]: dataFilter?.filterMaxPrice ?? null,
    [EProductsListFilter.sexId]: dataFilter?.sexes ?? [],
    [EProductsListFilter.sizes]: dataFilter?.filterSizes ?? [],
    [EProductsListFilter.waists]: dataFilter?.filterWaists ?? undefined,
  };

  filterData[EProductsListFilter.sexId] =
    getAndMapSexes(pageDataData, filterData, pageDataType || 'products', pageSexId) || filterData[EProductsListFilter.sexId];
  filterData[EProductsListFilter.sexIdMob] = getAllSexes(pageDataData, pageSexId) || [];

  const isSearchPage = !!productListFilter?.textSearch;
  if (isSearchPage) {
    filterData[EProductsListFilter.sexId] = getAllSexes(pageDataData, pageSexId) || [];
  }
  return filterData;
};

export const getPageSexId = (state: IStore) => {
  const pageSexId =
    state.productsList.dataFilter?.sexes?.length === 1 && state.productsList.dataFilter?.sexes[0]
      ? state.productsList.dataFilter?.sexes[0].sexId
      : state.pageData?.data?.filter?.sexId;
  return pageSexId ? Number(pageSexId) : pageSexId;
};

export const getPageSexIdNew = (productsListDataSexes: ISexItem[], pageDataDataFilter: IFilter | undefined) => {
  const pageSexId =
    productsListDataSexes?.length === 1 && productsListDataSexes[0]
      ? productsListDataSexes[0].sexId
      : pageDataDataFilter?.sexId;
  return pageSexId;
};

export const getFilterData = (payload: IProductsList) => {
  const {
    count,
    errors,
    size,
    products,
    ...filterData
  } = payload;

  return filterData;
};
export const getListData = (payload: IProductsList) => {
  const {
    count,
    errors,
    size = itemsPerPage,
    products,
  } = payload;

  return {
    count,
    errors,
    products,
    size,
  };
};
