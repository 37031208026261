import { IProductColor, IProductInList } from '@interfaces/product';
import React, { ReactNode } from 'react';

export interface IProductDataContext {
  currentColor: IProductColor | undefined;
  isGiftCard: boolean | null;
  isNotAvailable: boolean | undefined;
  productId: number | undefined;
  sizeId?: number;
  withLike: boolean | undefined;
  item: IProductInList | undefined;
  rightAction?: ReactNode;
}

const defaultValues: IProductDataContext = {
  currentColor: undefined,
  isGiftCard: null,
  isNotAvailable: undefined,
  productId: undefined,
  withLike: undefined,
  item: undefined
};

export const ProductDataContext = React.createContext<IProductDataContext>(defaultValues);
