import classNames from 'classnames';
import React, { PropsWithChildren } from 'react';
import { useSelector } from 'react-redux';

import HiddenMy from '@components/common/Hidden';
import { parseStyles } from '@components/common/customBanners/utils';
import { mobileMode } from '@constants/config';

import { ICustomBanner } from '@interfaces/customBanners/actions';
import { EContentShowUserAuth } from '@interfaces/pageData/reducer';
import { IStore } from '@interfaces/store';

import { useStyles } from './styles';


interface ISalePriceCampaignProps extends PropsWithChildren {
  banner: ICustomBanner;
  className?: string;
  onClick?: () => void;
  defaultView?: React.ReactNode;
}

const withInitialCheck = (Component: React.FunctionComponent<ISalePriceCampaignProps>) => {
  const WrappedComponent: React.FunctionComponent<ISalePriceCampaignProps> = (props) => {
    const { banner} = props;
    if (!banner?.content_show || !banner?.content_settings) {
      return  null;
    }
    return <Component {...props} />;
  };

  WrappedComponent.displayName = `withInitialCheck(${Component.displayName || Component.name || 'Component'})`;

  return WrappedComponent;
};

const CustomBannerContainer: React.FunctionComponent<ISalePriceCampaignProps> = ({
  banner,
  children,
  className,
  defaultView,
  onClick,
}) => {
  const isAuthorized = useSelector<IStore, boolean>((state) => state.auth.isAuthorized);

  const { content_settings, content_show } = banner;
  const { content_id, color, colorBackground, fontSize } = content_settings;

  const classes = useStyles({
    color,
    colorBackground,
    fontSize,
  });

  if (content_show?.user_auth && content_show.user_auth !== EContentShowUserAuth.all) {
    if (content_show.user_auth === EContentShowUserAuth.logged_in && !isAuthorized) {
      return null;
    }
    if (content_show.user_auth === EContentShowUserAuth.logged_out && isAuthorized) {
      return null;
    }
  }

  const parsedStyles = parseStyles(content_settings?.style);
  return (
    <>
      <HiddenMy
        mdDown={mobileMode ? !content_show?.show_in_app : !content_show?.show_in_mobile}
        mdUp={mobileMode ? !content_show?.show_in_app : !content_show?.show}>
        <div
          className={classNames(classes.container, content_settings?.className, className)}
          style={parsedStyles}
          id={content_id || undefined}
          onClick={onClick}
        >
          {children}
        </div>
      </HiddenMy>
      <HiddenMy
        mdDown={mobileMode ? content_show?.show_in_app : content_show?.show_in_mobile}
        mdUp={mobileMode ? content_show?.show_in_app : content_show?.show}>
        {defaultView}
      </HiddenMy>
      {defaultView}
    </>
  );
};

export default withInitialCheck(CustomBannerContainer);
