import classNames from 'classnames';
import React, { useContext } from 'react';
import { useSelector } from 'react-redux';

import SaleTag from '@components/common/customBanners/types/SaleTag';
import useSaleTag from '@components/common/ProductFullResponsive/ProductImage/SaleCheckHook';
import { ProductDataContext } from '@components/common/ProductFullResponsive/productContext';
import ErrorBoundary from '@components/common/ErrorBoundary';
import { EUserAgent } from '@constants/app';
import { thresholdStockLow } from '@constants/index';
import { useTranslation } from '@hooks/useTranslation';
import { IProductSize } from '@interfaces/product';
import { IStore } from '@interfaces/store';

import imageStyles from './styles';

interface IProductImageAvailabilityProps {
  pictureLoaded: boolean;
}

const ProductImageAvailability: React.FunctionComponent<IProductImageAvailabilityProps> = ({
  pictureLoaded,
}) => {
  const classesImage = imageStyles();
  const { t } = useTranslation();
  const userAgentType = useSelector<IStore, EUserAgent | null>((state: IStore) => state.context.userAgentType);

  const { currentColor, isGiftCard, sizeId, isNotAvailable, item } = useContext(ProductDataContext);

  const campaignId = currentColor?.price.campaignId;

  const stockQty = item?.colors?.map((color) => color.sizes?.map((size) => size.stockQty)?.flat())?.flat() || [];
  const currentSize: IProductSize | null = sizeId !== undefined ? currentColor?.sizes.find((size) => size.sizeId === sizeId) || null : null;
  const maxStockValue = currentSize ? currentSize.stockQty : Math.max(...stockQty);

  const { tagToShow } = useSaleTag({ campaignId, productPrice: currentColor?.price });

  return (
    <ErrorBoundary>
      {tagToShow ? (<SaleTag tag={tagToShow} />) : (
        <>
          {(!isGiftCard && pictureLoaded && (maxStockValue < thresholdStockLow) || isNotAvailable) && (
            <div
              className={classNames(classesImage.stockStatus, { [classesImage.stockStatusBlur]: userAgentType !== EUserAgent.android })}>
              {maxStockValue < 1 || isNotAvailable ? t('cart.product_qty_out') : t('cart.product_qty_available', { qty: maxStockValue })}
            </div>)}
        </>
      )}
    </ErrorBoundary>
  );
};

export default ProductImageAvailability;
